.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  background-color: #ffffff; /* McDonald's yellow */
  position: relative; /* Add position relative to the parent */
}

.background-container {
  position: fixed;
  top: 5vh;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0; /* Place the background behind other elements */
}

.icons-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background-color: #ffc600; /* McDonald's red */
  padding: 10px;
  z-index: 10;
}

.content {
  position: relative; /* Add position relative to the content */
  z-index: 20; /* Increase the z-index to make it visible */
}

.mcdonalds-application-button {
  font-size: 24px;
  background-color: #da291c;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mcdonalds-application-button:hover {
  background-color: #b21f18;
}


.icon {
  margin: 0 10px; /* Add some spacing between icons */
}

.icon-image {
  width: 150px; /* Adjust size as needed */
  height: 150px; /* Adjust size as needed */
  transition: transform 0.3s ease; /* Add transition for smooth scaling */
}

.icon-image:hover {
  transform: scale(1.2); /* Scale up the icon on hover */
}

.contract-address {
  margin-top: 20px; /* Add some spacing from the top */
  position: relative; /* Change to relative for normal flow */
  color: rgb(255, 255, 255);
  font-size: 1.5em;
  text-shadow: 0 0 5px rgb(0, 0, 0), 0 0 10px rgb(255, 255, 255), 0 0 15px rgb(0, 0, 0), 0 0 20px rgb(255, 255, 255), 0 0 25px rgb(0, 0, 0), 0 0 30px rgb(255, 255, 255), 0 0 35px rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
}

.image3-container {
  width: 100%;
  z-index: 1; /* Ensure image3 is above the background */
}

.App-footer {
  font-size: 1em;
  color: rgb(0, 0, 0);
  text-shadow: 0 0 5px rgb(255, 255, 255), 0 0 10px rgba(0, 0, 255, 0), 0 0 15px rgb(255, 255, 255), 0 0 20px rgb(0, 0, 0), 0 0 25px rgb(255, 255, 255), 0 0 30px rgb(0, 0, 0), 0 0 35px rgb(255, 255, 255);
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  background-color: rgba(255, 255, 255, 0); /* Make the footer background transparent */
  padding: 10px;
  border-radius: 5px;
  position: relative;
  background-color: #ffc600;
  color: white;
}

.copied-message {
  position: absolute;
  top: -30px; /* Position the message above the footer */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8em;
  text-shadow: none; /* Remove text shadow for better readability */
}

.landing-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1000;
}

.enter-button {
  padding: 15px 30px;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  border: 2px solid white;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative; /* Ensures the button stays on top of the image */
  z-index: 1001; /* Higher than the landing page z-index */
}

.enter-button:hover {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}

.iframe-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the iframe is on top of everything */
}

.job-application-iframe {
  width: 80%;
  height: 80%;
  border: none;
}

.close-iframe-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #da291c;
  color: white;
  border: none;
  cursor: pointer;
}

@media (max-width: 600px) {
  .App {
    height: auto; /* Allow height to adjust based on content */
  }

  .background-container {
    height: 100%; /* Adjust height to cover the content */
    background-size: contain;
  }

  .content {
    width: 100%;
  }

  .icons-bar {
    flex-wrap: wrap; /* Allow icons to wrap to the next line */
    margin-bottom: 2vh; /* Adjust spacing */
  }

  .icon {
    margin: 5px; /* Adjust spacing between icons */
  }

  .icon-image {
    width: 76px; /* Adjust size for mobile */
    height: 76px; /* Adjust size for mobile */
  }

  .contract-address {
    font-size: 1em; /* Adjust font size for mobile */
    padding: 5px; /* Adjust padding */
  }

  .App-footer {
    font-size: 0.8em; /* Adjust font size for mobile */
  }

  .mcdonalds-application-button {
    font-size: 24px;
    background-color: #da291c;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-bottom: 6vh;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 3;
  }
  
  .mcdonalds-application-button:hover {
    background-color: #b21f18;
    z-index: 3;
  }

  .copied-message {
    font-size: 0.7em;
  }

  .mobile-title {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    color: #ffc600;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .job-application-iframe {
    width: 100%;
    height: 100%;
  }
}